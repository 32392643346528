body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.white-background{
  padding: 50px 80px;
  background: #FFFFFF;  
}

.background{
  padding: 50px 80px;
  background: linear-gradient(270.15deg, #E9EAEC 0.17%, #F6FAFD 72.8%, #F0F5F9 98.9%);
}

.navbar-item-disactivated{
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}

.inherit{
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-top: 1px
}

.navbar-item-activated{
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}

.search-panel{
  /** background: linear-gradient(270deg, #FEFEFE 33.83%, rgba(202, 218, 231, 0) 103.09%); **/
  background-image: url('./images/search-panel-background.svg');
  border: 1px solid #000000;
  border-radius: 20.6434px;
  width: 100%;
}

.search-panel-text{
  font-weight: 600;
  font-size: 35px;
  color: #000000;
}

.search-input{
  background: #FFFFFF;
  box-shadow: 0px 2px 11px rgba(153, 153, 153, 0.1);
  border-radius: 51px;
  padding: 20px 40px;
  width: 100%;
  border: none;
  outline: none;
}

.relative{
  position: relative
}

.search-input::placeholder{
  font-weight: 500;
  font-size: 17px;
  color: #808080;
}

.search-icon{
  position: absolute;
  right: -50px;
  top: 17px
}

.items-you-are-watching-text{
  font-weight: 600;
  font-size: 30px;  
  color: #000000;
}

.show-all-text{
  font-weight: 600;
  font-size: 18px;
  color: #757575;
  cursor: pointer;
}

.product-image{
  width: 100%;
  max-height: 280px;
  border-radius: 20px;
}

.product-image-2{
  width: 100%;
  height: 355px;
  border-radius: 20px;
}

.product-comment{
  font-weight: 500;
  font-size: 15px;
  color: #959595;
}

.product-name{
  font-weight: 600;
  font-size: 18px;
  color: #080028;
}

.sales-product-name{
  font-weight: 600;
  font-size: 25px;
  color: #080028;
}

.sales-new-price{
  font-weight: bold;
  font-size: 20px;
  color: #080028;
}

.sales-original-price{
  font-weight: 500;
  font-size: 17px;
  color: #080028;
  text-decoration: line-through;
}

.sales-description{
  font-weight: normal;
  font-size: 15px;
  color: #021428;
}

.product-price{
  font-weight: bold;
  font-size: 20px;
  color: #080028;
}

.product-discount{
  font-weight: 600;
  font-size: 16px;
  color: #080028;
}

.sales-discount{
  font-weight: bold;
  font-size: 13.2356px;
  color: #55C961;
}

.text-button{
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
}

.full-height{
  height: 100%;
}

.pointer{
  cursor: pointer;
}

.carousel-container {
  width: 100%;
  margin: 30px
}

.sales-carousel-container {
  width: 100%;
}
/*.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.header-background{
  background-color: #FFFFFF;
  z-index: 9;
  padding: 30px 120px;
}*/

.little-product-name{
  font-weight: 600;
  font-size: 15px;
  color: #626262;
}

.demo-page-title{
  font-size: 36px;
  font-weight: bold;
  color: #080028;
}
.demo-label {
  font-weight: 500;
  font-size: 16px;
  color: #080028;
}

.demo-discount{
  font-weight: bold;
  font-size: 20px;
  color: #080028;
}
.sales-card-box{
  border: 1px solid #080028;
  border-radius: 12px;
  padding: 20px
}

.product-brand-container{
  width: 100px;
  height: 50px;
}

.details-product-brand-container{
  width: 200px;
  height: 50px;
}

.details-product-name{
  font-weight: 600;
  font-size: 30px;
  color: #080028;
}

.product-brand{
  width: 100%;
  max-height: 100%
}

.opaque{
  opacity: 0.3;
}

.relative{
  position: relative;
}

.size-icon{
  width: 24.17px;
  height: 24.17px;
  border-radius: 24.17px;
  border: 0.3px solid #000000;
  font-weight: 600;
  font-size: 10.3583px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #080028;
  cursor: pointer;
  margin-right: 3px;
  background-color: #FFFFFF;
}

.colors-container{
  position: absolute;
  top: 20px;
  left: 20px
}

.sizes-container{
  position: absolute;
  top: 55px;
  left: 20px
}

.color-icon{
  width: 24.17px;
  height: 24.17px;
  border-radius: 24.17px;
  border: 0.3px solid #000000;
  cursor: pointer;
  margin-right: 3px
}

.akadinia-logo{
  width: 250px
}

.main-photo{
  width: 100%;
  border-radius: 20px
}

.sub-photos{
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.details-discount{
  font-weight: 700;
  font-size: 18px;
  color: #55C961;
}

.akadinia-page{
  font-weight: 600;
  font-size: 18px;
  color: #080028;
}

.alert-panel{
  background-image: url('./images/alert-panel-background.svg');
  /*background: linear-gradient(270deg, #FEFEFE 33.83%, rgba(202, 218, 231, 0) 103.09%);*/
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 40px 20px;
}

.alert-panel-text{
  font-weight: 500;
  font-size: 26px;
  color: #080028;
  padding-left: 30px;
}

.alert-panel-link{
  font-weight: 700;
  font-size: 25px;
  color: #080028;
  padding-right: 10px

}

.relative{
  position: relative;
}

.warning-icon{
  position: absolute;
  top: 2.5px
}

.messenger-icon{
  position: absolute;
  bottom: 3px
}

@media screen and (max-width: 900px) {
  
  .alert-panel-text{
    font-size: 20px;
  }
  
  .alert-panel-link{
    font-size: 20px;
  }

  .warning-icon{
    top: -1.5px;
    width: 25px;
  }
  
  .messenger-icon{
    bottom: 0px;
  }

  .details-discount{
    font-weight: 600;
    font-size: 12px;
  }

  .details-product-brand-container{
    width: 150px;
    height: 40px;
  }
  
  .details-product-name{
    font-weight: 600;
    font-size: 20px;
    color: #080028;
  }

  .product-image-2{
    height: 258px;
  }

  .sales-product-name{
    font-size: 18px;
  }
  
  .sales-new-price{
    font-size: 19px;
  }
  
  .sales-original-price{
    font-size: 15px;
  }
  
  .white-background{
    padding: 50px 50px;
  }
  
  .background{
    padding: 50px 50px;
  }

  /*.header-background{
    padding: 30px 50px;
  }*/

  .akadinia-logo{
    width: 150px
  }

  .search-panel{
    padding: 30px 0px;
  }

  .search-panel-text{
    font-size: 25px;
  }

  .search-input{
    padding: 15px 20px;
    width: 100%;
    max-width: 500px;
  }

  .items-you-are-watching-text{
    font-size: 22px;  
  }
  
  .show-all-text{
    font-size: 16px;
  }
}

@media screen and (min-width: 750px) and (max-width: 900px){

  .search-icon{
    position: absolute;
    right: 70px;
    top: 12px
  }
}

@media screen and (max-width: 750px) {

  .search-icon{
    position: absolute;
    right: 20px;
    top: 12px
  }
}